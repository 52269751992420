import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Team from "../components/team"
import SwitcherButtonsContent from "../components/switcher-buttons-content"
import ArticleSlider from "../components/article-slider"
//import FeaturedSponsorship from "../components/featured-sponsorship"
import Newsletter from "../components/newsletter"
import CtaSlider from "../components/cta-slider"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import BackgroundImage from "../images/slider-image-v7.jpg"
import { Iframe } from "../components/iframe"
import ReactLightbox from "../components/react-lightbox"

const AboutUsPage = pageContext => {
  //console.log("About us - pageContext: ", pageContext)

  const { strapiAboutUs, allStrapiArticle, allStrapiGallerySection } =
    useStaticQuery(graphql`
      query {
        strapiAboutUs {
          hero {
            ...StrapiHero
          }
          seo {
            ...StrapiSeo
          }
          callToAction {
            ...StrapiCallToAction
          }
          slideShowSyncedWithSlider {
            ...SlideShowSyncedWithSliderSections
          }
          featuredSponsorship {
            ...SponsorshipSections
          }
          testimonialSection {
            ...TestimonialCards
          }
          switcherContent {
            ...SwitcherSections
          }
          newsletter {
            headings {
              title
              description
            }
            description
          }
          video {
            headings {
              title
              description
            }
            src
            title
            width
            height
            bg
          }
        }
        allStrapiArticle(
          filter: {
            category: { name: { eq: "Story" } }
            featured: { eq: true }
            location: { eq: "about-us-page" }
          }
          sort: { order: DESC, fields: updatedAt }
        ) {
          nodes {
            ...StrapiArticles
          }
        }
        allStrapiGallerySection(filter: { location: { eq: "about-us-page" } }) {
          nodes {
            ...StrapiGallerySection
          }
        }
      }
    `)

  const {
    hero,
    seo,
    callToAction,
    //featuredSponsorship,
    testimonialSection,
    switcherContent,
    newsletter,
    video,
  } = strapiAboutUs

  //const sponsorshipSections = featuredSponsorship

  const articles = allStrapiArticle.nodes

  const callToActionSections = callToAction.call_to_action_sections

  const testimonialCards = testimonialSection.testimonial_cards

  const switcherSection = switcherContent.switcher_sections

  const gallerySection = allStrapiGallerySection.nodes[0]

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Team
        title="Who we are!"
        description="Welcome to our about us section, meet the team below, see what we do, and why we do this!"
        bg="uk-background-default"
      />

      <SwitcherButtonsContent
        switcherSections={switcherSection}
        title="Our hopes and dreams"
        description="A non-profit organisation, advocating for the homeless, vulnerable street dogs of Sri Lanka, as they suffer from hunger, starvation, disease, and terrible abuse."
        bg="uk-background-muted"
      />

      {/* <FeaturedSponsorship
        sponsorshipSections={sponsorshipSections}
        title="Featured Sponsorship"
        description="Checkout some of our success stories, see how your donations and sponsorships have helped these street dogs, and the work we do"
        bg="uk-background-default"
      /> */}

      <TestimonialSliderBackgroundImage
        BackgroundImage={BackgroundImage}
        testimonialCards={testimonialCards}
      />

      <Newsletter
        newsletter={newsletter}
        title={newsletter.headings[0].title}
        description={newsletter.headings[0].description}
        bg="uk-background-muted"
      />

      <ReactLightbox
        images={gallerySection.images}
        location={gallerySection.location}
        title={gallerySection.headings.title}
        description={gallerySection.headings.description}
        bg="uk-background-default"
        //remainingImages={remainingImages}
      />

      <Iframe video={video} />

      <ArticleSlider
        articles={articles}
        title="Featured Stories"
        description="Checkout some of our stories from SaveTheStreetPooches Sri Lanka"
        bg="uk-background-default"
      />

      <CtaSlider
        title={callToAction.title}
        description={callToAction.description}
        callToActionSections={callToActionSections}
        bg={callToAction.bg}
        image={callToAction.image}
        paypalButton={callToAction.paypalButton}
      />
    </>
  )
}

export default AboutUsPage
