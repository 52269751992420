import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Headings from "./headings"

const Team = ({ title, description, bg = "uk-background-muted" }) => {
  return (
    <div className={`${bg} uk-margin-small-top`}>
      <Headings title={title} description={description} bg={bg} />
      <div
        //className="uk-section uk-section-muted uk-position-relative uk-overflow-hidden uk-padding-remove-top"
        className={`uk-section ${bg} uk-position-relative uk-overflow-hidden uk-padding-remove uk-margin-medium-bottom`}
        uk-scrollspy="cls:uk-animation-fade; offset-top: 80px; delay: 300"
      >
        <div className="uk-container uk-position-relative">
          <div
            className="uk-flex uk-flex-center"
            uk-grid="true"
            uk-scrollspy="cls:uk-animation-fade; target: .team-member-item; delay: 300"
          >
            <div className="uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-transition-toggle team-member-item">
              <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image uk-height-medium">
                <StaticImage
                  src="../images/chulie-v7.jpg"
                  alt="Chulie with the dogs at the shelter"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  title="Chulie with the dogs at the shelter"
                  style={{ height: "100%" }}
                />
                <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                  <ul className="uk-iconnav">
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                        href="https://www.facebook.com/savethestreetpooches"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on facebook"
                        alt="lets connect on facebook"
                        className="uk-icon-button"
                        uk-icon="facebook"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                        href="https://www.instagram.com/savethestreetpoochessrilanka7"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on Instagram"
                        alt="lets connect on instagram"
                        className="uk-icon-button"
                        uk-icon="instagram"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Email Chulie; pos: bottom-center"
                        href="mailto:chulie@stsp-srilanka.org?subject=Save The Street Pooches Sri Lanka"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on email"
                        alt="lets connect on email"
                        className="uk-icon-button"
                        uk-icon="mail"
                      >
                        {null}
                      </a>
                    </li>
                  </ul>
                  <div className="uk-margin-top	">
                    <Link
                      to={"/article/chulie-perera"}
                      toggle="button"
                      className="uk-button uk-button-default uk-button-small uk-text-small uk-text-capitalize"
                      style={{
                        borderColor: "#FFFFFF",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Chulie's Story
                    </Link>
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-left uk-margin-small-right">
                <div
                  className="uk-flex uk-flex-column uk-flex-center uk-text-center uk-hidden@s"
                  grid="true"
                >
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Chulie Perera:
                  </span>
                  {/* <span>&nbsp;&nbsp;</span> */}
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Founder - Director
                  </span>
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-visible@s">
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Chulie Perera:
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Founder - Director
                  </span>
                </div>
                <div className="uk-flex uk-flex-center uk-text-center">
                  <span
                    className="uk-display-block uk-text-small"
                    style={{ color: "#617F7F", fontWeight: "400" }}
                  >
                    Save The Street Pooches Sri Lanka - SL
                  </span>
                </div>
              </div>
            </div>

            <div className="uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-transition-toggle team-member-item">
              <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image uk-height-medium">
                <StaticImage
                  src="../images/Juno-cuddle-v1.jpg"
                  alt="home-section-one"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  title="home-section-one"
                  style={{ height: "100%" }}
                />
                <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                  <ul className="uk-iconnav">
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                        href="https://www.facebook.com/savethestreetpooches"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on facebook"
                        alt="lets connect on facebook"
                        className="uk-icon-button"
                        uk-icon="facebook"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                        href="https://www.instagram.com/savethestreetpoochessrilanka7"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on Instagram"
                        alt="lets connect on instagram"
                        className="uk-icon-button"
                        uk-icon="instagram"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Email Susan; pos: bottom-center"
                        href="mailto:info@stsp-srilanka.org?subject=Save The Street Pooches Sri Lanka"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on email"
                        alt="lets connect on email"
                        className="uk-icon-button"
                        uk-icon="mail"
                      >
                        {null}
                      </a>
                    </li>
                  </ul>
                  <div className="uk-margin-top	">
                    <Link
                      to={"/article/susan-wells"}
                      toggle="button"
                      className="uk-button uk-button-default uk-button-small uk-text-small uk-text-capitalize"
                      style={{
                        borderColor: "#FFFFFF",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Susan's Story
                    </Link>
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-left uk-margin-small-right">
                <div
                  className="uk-flex uk-flex-column uk-flex-center uk-text-center uk-hidden@s"
                  grid="true"
                >
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Susan Wells:
                  </span>
                  {/* <span>&nbsp;&nbsp;</span> */}
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Founder - Director
                  </span>
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-visible@s">
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Susan Wells:
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Founder - Director
                  </span>
                </div>
                <div className="uk-flex uk-flex-center uk-text-center">
                  <span
                    className="uk-display-block uk-text-small"
                    style={{ color: "#617F7F", fontWeight: "400" }}
                  >
                    Save The Street Pooches Sri Lanka - UK
                  </span>
                </div>
              </div>
            </div>

            <div className="uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-transition-toggle team-member-item">
              <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image uk-flex uk-flex-center uk-flex-middle">
                <StaticImage
                  src="../images/allison-and-dog-v1.jpg"
                  alt="Allison Fletcher with Shyam a rescue dog in Sri Lanka"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  title="Allison Fletcher Team Profile"
                  style={{ height: "100%" }}
                />

                <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                  <ul className="uk-iconnav">
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                        href="https://www.facebook.com/savethestreetpooches"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on facebook"
                        alt="lets connect on facebook"
                        className="uk-icon-button"
                        uk-icon="facebook"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                        href="https://www.instagram.com/savethestreetpoochessrilanka7"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on Instagram"
                        alt="lets connect on instagram"
                        className="uk-icon-button"
                        uk-icon="instagram"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Email Allison; pos: bottom-center"
                        href="mailto:allison@sstsp-srilanka.org?subject=Save The Street Pooches Sri Lanka"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on email"
                        alt="lets connect on email"
                        className="uk-icon-button"
                        uk-icon="mail"
                      >
                        {null}
                      </a>
                    </li>
                  </ul>
                  <div className="uk-margin-top	">
                    <Link
                      to={"/article/allison-fletcher"}
                      toggle="button"
                      className="uk-button uk-button-default uk-button-small uk-text-small uk-text-capitalize"
                      style={{
                        borderColor: "#FFFFFF",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Allison's Story
                    </Link>
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-left uk-margin-small-right">
                <div
                  className="uk-flex uk-flex-column uk-flex-center uk-text-center uk-hidden@s"
                  grid="true"
                >
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Allison Fletcher:
                  </span>
                  {/* <span>&nbsp;&nbsp;</span> */}
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Director
                  </span>
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-visible@s">
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Allison Fletcher:
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Director
                  </span>
                </div>
                <div className="uk-flex uk-flex-center uk-text-center">
                  <span
                    className="uk-display-block uk-text-small"
                    style={{ color: "#617F7F", fontWeight: "400" }}
                  >
                    Save The Street Pooches Sri Lanka - UK
                  </span>
                </div>
              </div>
            </div>

            <div className="uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-transition-toggle team-member-item">
              <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image">
                <StaticImage
                  src="../images/herman-with-Aaliyah.jpg"
                  alt="herman Adu with Aaliyah a rescue dog in srilanka"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="blurred"
                  title="Herman Adu Team Profile"
                  style={{ height: "100%", width: "100%" }}
                />
                <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                  <ul className="uk-iconnav">
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                        href="https://www.facebook.com/savethestreetpooches"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on facebook"
                        alt="lets connect on facebook"
                        className="uk-icon-button"
                        uk-icon="facebook"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                        href="https://www.instagram.com/savethestreetpoochessrilanka7"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on Instagram"
                        alt="lets connect on instagram"
                        className="uk-icon-button"
                        uk-icon="instagram"
                      >
                        {null}
                      </a>
                    </li>
                    <li>
                      <a
                        uk-tooltip="title: Email Herman; pos: bottom-center"
                        href="mailto:herman@stsp-srilanka.org?subject=Save The Street Pooches Sri Lanka"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="lets connect on email"
                        alt="lets connect on email"
                        className="uk-icon-button"
                        uk-icon="mail"
                      >
                        {null}
                      </a>
                    </li>
                  </ul>
                  <div className="uk-margin-top	">
                    <Link
                      to={"/article/herman-adu"}
                      className="uk-button uk-button-default uk-button-small uk-border-pill button-hover uk-text-capitalize buttonHover"
                    >
                      Hermans's Story
                    </Link>
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-left uk-margin-small-right">
                <div
                  className="uk-flex uk-flex-column uk-flex-center uk-text-center uk-hidden@s"
                  grid="true"
                >
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Herman Adu:
                  </span>
                  {/* <span>&nbsp;&nbsp;</span> */}
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Volunteer
                  </span>
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-visible@s">
                  <span
                    className="uk-display-block uk-text-bold uk-text-small"
                    style={{ color: "#617F7F" }}
                  >
                    Herman Adu:
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span
                    className="uk-display-block uk-text-small uk-text-bold"
                    style={{ color: "#617F7F" }}
                  >
                    Volunteer
                  </span>
                </div>
                <div className="uk-flex uk-flex-center uk-text-center">
                  <span
                    className="uk-display-block uk-text-small"
                    style={{ color: "#617F7F", fontWeight: "400" }}
                  >
                    Save The Street Pooches Sri Lanka - UK
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
